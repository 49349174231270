import PatchLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patch-locations/PatchLocationsTemplate';

import { PATCH_LOCATIONS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/PatchLocations/PatchLocationsTemplate'
};

const createStory = props => () => ({
  components: { PatchLocationsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <patch-locations-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  patchLocations: [PATCH_LOCATIONS.SCALP, PATCH_LOCATIONS.EARS]
});
